import React from 'react';
import { ShippingMethod } from '@Types/cart/ShippingMethod';
import { useFormat } from 'helpers/hooks/useFormat';
import { useCart } from 'frontastic';
import CartItems from './cart-items';
import { BusinessUnit } from '@Types/business-unit/BusinessUnit';
import { useBusinessUnitStateContext } from 'frontastic/provider/BusinessUnitState';

type OverviewProps = {
  shippingMethods: ShippingMethod[];
  currentShippingMethod: ShippingMethod;
  onSelectShippingMethod: (shippingMethodId: ShippingMethod) => void;
  onDeliveryDateChange: (deliveryDate: string) => void;
  inputExepectedDeliveryDateRef: React.Ref<HTMLInputElement>,
};

const Overview: React.FC<OverviewProps> = ({ shippingMethods, currentShippingMethod, onSelectShippingMethod, onDeliveryDateChange, inputExepectedDeliveryDateRef }) => {
  const { data } = useCart();
  const { businessUnit } = useBusinessUnitStateContext();

  const { formatMessage } = useFormat({ name: 'checkout' });

  const getMinDeliveryDate = () => {
    const hasDangerousProduct = data.lineItems.find((lineItem) => {
      return lineItem.variant?.attributes?.produit_dangereux === true;
    });
    const hasProductFromMex = data.lineItems.find((lineItem) => {
      return lineItem.variant?.attributes?.country_of_origin?.toLowerCase() === "mx";
    });
    const date = new Date();
    date.setDate(date.getDate() + (hasProductFromMex ? 120 : hasDangerousProduct ? 90 : 60));

    return new Intl.DateTimeFormat('fr-CA', {year: "numeric", month:"2-digit", day:"2-digit"}).format(date);
  };

  return (
    <>
      <section aria-labelledby="cart-heading" className="bg-white md:rounded md:shadow-md lg:col-span-7 lg:p-5">
        <div className="border-b-4 border-neutral-100 px-4 py-5 md:px-6 lg:px-0">
          <form>
            <div className="mb-4 text-xs font-bold uppercase leading-tight text-neutral-600">
              <span>{formatMessage({ id: 'yourShippingMethod', defaultMessage: 'Your shipping method' })}</span>
            </div>

            {shippingMethods?.map(({ shippingMethodId, name, description, rates, key }, index) => (
              <label
                htmlFor={name}
                key={index}
                className={`flex h-16 cursor-pointer items-center rounded border border-neutral-400 px-4 py-3 ${
                  index > 0 && 'mt-2'
                }`}
              >
                <input
                  type="radio"
                  aria-label="Shipping method"
                  name="shippingMethodId"
                  id={name}
                  className="mr-2 h-4 w-4 border-gray-300 text-accent-400 focus:ring-accent-400"
                  checked={currentShippingMethod?.shippingMethodId === shippingMethodId}
                  value={shippingMethodId}
                  onChange={() => onSelectShippingMethod(shippingMethods[index])}
                />
                <div className="flex w-full flex-col">
                  <div className="text-base capitalize leading-tight">{name}</div>
                  <span className="text-xs text-neutral-600">{"shipping-default" !== key || ("shipping-default" === key && businessUnit?.custom?.fields?.shippingMethod) && description} {"shipping-default" === key && businessUnit?.custom?.fields?.shippingMethod}</span>
                </div>
                {/* <span className="ml-auto text-base font-bold">
                  <span className=" ">{CurrencyHelpers.formatForCurrency(rates?.[0]?.price)}</span>
                </span> */}
              </label>
            ))}
            <div className="mt-4 mb-4 text-xs font-bold uppercase leading-tight text-neutral-600">
              <span>{formatMessage({ id: 'delivery', defaultMessage: 'Delivery' })}</span>
            </div>
            <label htmlFor='delivery_date'>
                <span className='ml-2 mr-2 text-xs font-bold leading-tight text-neutral-600'>{formatMessage({ id: 'date', defaultMessage: 'Date' })}</span>
                <input 
                  type='date' 
                  name='delivery_date'
                  required={true}
                  ref={inputExepectedDeliveryDateRef}
                  className="border-gray-300 text-accent-400 focus:ring-accent-400"
                  onChange={(event) => onDeliveryDateChange(event.target.value)}
                  min={getMinDeliveryDate()}
                />
            </label>
          </form>
        </div>
        <div className="py-5">
          <div className=" text-xs font-bold uppercase leading-tight text-neutral-600 px-6">
            <span>{formatMessage({ id: 'overview.order', defaultMessage: 'YOUR ORDER' })}</span>
          </div>

          <CartItems cart={data} />
        </div>
      </section>
    </>
  );
};

export default Overview;
